import React from 'react'
import { Trans, useTranslation } from 'i18n'

import { ErrorPageLayout, Layout, NotificationBar } from 'components'
import { defaultGetStaticProps } from 'utilities/next'

const Custom404Page = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <NotificationBar />
      <div>
        <ErrorPageLayout
          title={
            <Trans t={t} i18nKey="common:notFoundTitle">
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              We can't find this page. Need help with something else?
              <a
                href="https://help.mmhmm.app"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact us
              </a>
            </Trans>
          }
        >
          <p>{t('common:notFoundDescription')}</p>
        </ErrorPageLayout>
      </div>
    </Layout>
  )
}

export const getStaticProps = defaultGetStaticProps

export default Custom404Page
